import React, {FunctionComponent} from "react";

export enum TagCloudItemType {
    SMALL,
    NORMAL,
    BIG,
    EXTRA_BIG
}

type TagCloudItemTypeItem = {
    type: TagCloudItemType;
    fontClass: string;
    minValue: number;
}

const TAG_CLOUD_ITEMS: TagCloudItemTypeItem[] = [
    {type: TagCloudItemType.EXTRA_BIG, fontClass: "tag-cloud-extra-big", minValue: 800},
    {type: TagCloudItemType.BIG, fontClass: "tag-cloud-big", minValue: 700},
    {type: TagCloudItemType.NORMAL, fontClass: "tag-cloud-normal", minValue: 600},
    {type: TagCloudItemType.SMALL, fontClass: "tag-cloud-small", minValue: 0}
];

type TagCloudItemProps = {
    name: string;
    score: number;
};

const TagCloudItem: FunctionComponent<TagCloudItemProps> = (props) => {
    const obj = TAG_CLOUD_ITEMS.find(({minValue}) => props.score > minValue);

    if (!obj) return <></>;
    return (
        <span className={obj.fontClass}>{props.name}</span>
    );
};

export default TagCloudItem;
