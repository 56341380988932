import {ContextualCategory} from "../api/model/ContextualCategory";

export class BrandSafety {
    type: string = "";
    icon: string = "";
    color: string = "";

    constructor(type: string, icon: string, color: string) {
        this.type = type;
        this.icon = icon;
        this.color = `var(--color-${color})`;
    }

    static SAFE: BrandSafety = new BrandSafety("safe", "verified", "green");
    static WARNING: BrandSafety = new BrandSafety("warning", "warning", "orange");
    static ALERT: BrandSafety = new BrandSafety("alert", "error", "red");

    static forCategory(category: ContextualCategory): BrandSafety {
        if (!category) return BrandSafety.SAFE;
        if (category.relevancy * 100 > 40) return BrandSafety.ALERT;
        return BrandSafety.WARNING;
    }
}
