import {IconProps} from "../../snippet/Icon";

export enum TopCategory {
    APPAREL = "APPAREL",
    ARTS_AND_ENTERTAINMENT = "ARTS & ENTERTAINMENT",
    AUTOMOTIVE = "AUTOMOTIVE",
    AUTOS_AND_VEHICLES = "AUTOS & VEHICLES",
    B2B_SUPPLIES_AND_SERVICES = "B2B SUPPLIES & SERVICES",
    BANK_AND_INSURANCE = "BANK & INSURANCE",
    BEAUTY_AND_PERSONAL_CARE = "BEAUTY & PERSONAL CARE",
    BEHAVIORAL = "BEHAVIORAL",
    BOOKS_AND_LITERATURE = "BOOKS AND LITERATURE",
    BRAND = "BRAND",
    BUSINESS_AND_INDUSTRY = "BUSINESS & INDUSTRY",
    BUSINESS_AND_FINANCE = "BUSINESS AND FINANCE",
    CAREERS = "CAREERS",
    CHILDCARE = "CHILDCARE",
    ECOLOGY_ENVIRONMENT_AND_NATURAL_RESSOURCES = "ECOLOGY, ENVIRONMENT AND NATURAL RESSOURCES",
    EDUCATION = "EDUCATION",
    EVENTS_AND_ATTRACTIONS = "EVENTS AND ATTRACTIONS",
    FAMILY_AND_RELATIONSHIPS = "FAMILY AND RELATIONSHIPS",
    FINE_ART = "FINE ART",
    FOOD_AND_DRINK = "FOOD & DRINK",
    HEALTHY_LIVING = "HEALTHY LIVING",
    HIGH_TECH_AND_ELECTRONICS = "HIGH TECH & ELECTRONICS",
    HOBBIES_AND_INTERESTS = "HOBBIES & INTERESTS",
    HOME_AND_GARDEN = "HOME & GARDEN",
    LANGUAGES = "LANGUAGES",
    LIFE_EVENTS = "LIFE EVENTS",
    MEDICAL_HEALTH = "MEDICAL HEALTH",
    MOVIES = "MOVIES",
    MUSIC_AND_AUDIO = "MUSIC AND AUDIO",
    NEWS = "NEWS",
    NEWS_AND_POLITICS = "NEWS AND POLITICS",
    PERSONAL_FINANCE = "PERSONAL FINANCE",
    PETS = "PETS",
    PETS_AND_ANIMALS = "PETS & ANIMALS",
    POP_CULTURE = "POP CULTURE",
    REAL_ESTATE = "REAL ESTATE",
    RELIGION_AND_SPIRITUALITY = "RELIGION & SPIRITUALITY",
    SCIENCE = "SCIENCE",
    SHOPPING = "SHOPPING",
    SOCIO_DEMOGRAPHICS = "SOCIO-DEMOGRAPHICS",
    SPORTS = "SPORTS",
    STYLE_AND_FASHION = "STYLE & FASHION",
    TECHNOLOGY_AND_COMPUTING = "TECHNOLOGY & COMPUTING",
    TELEVISION = "TELEVISION",
    TRAVEL = "TRAVEL",
    VIDEO_GAMING = "VIDEO GAMING",
    WELL_BEING = "WELL-BEING"
}

export type TopCategoryItem = {
    category: TopCategory;
    localeKey: string;
    icon: IconProps;
}

export const TOP_CATEGORIES: TopCategoryItem[] = [
    {category: TopCategory.APPAREL, localeKey: "apparel", icon: {name: "checkroom"}},
    {category: TopCategory.ARTS_AND_ENTERTAINMENT, localeKey: "arts_and_entertainment", icon: {name: "theater_comedy"}},
    {category: TopCategory.AUTOMOTIVE, localeKey: "autos", icon: {name: "directions_car"}},
    {category: TopCategory.AUTOS_AND_VEHICLES, localeKey: "autos", icon: {name: "directions_car"}},
    {category: TopCategory.B2B_SUPPLIES_AND_SERVICES, localeKey: "b2b_supplies_and_services", icon: {name: "support_agent"}},
    {category: TopCategory.BANK_AND_INSURANCE, localeKey: "bank_and_insurance", icon: {name: "account_balance"}},
    {category: TopCategory.BEAUTY_AND_PERSONAL_CARE, localeKey: "beauty", icon: {name: "health_and_beauty"}},
    {category: TopCategory.BEHAVIORAL, localeKey: "behavioral", icon: {name: "psychology"}},
    {category: TopCategory.BOOKS_AND_LITERATURE, localeKey: "books", icon: {name: "menu_book"}},
    {category: TopCategory.BRAND, localeKey: "brand", icon: {name: "sell"}},
    {category: TopCategory.BUSINESS_AND_INDUSTRY, localeKey: "business", icon: {name: "factory"}},
    {category: TopCategory.BUSINESS_AND_FINANCE, localeKey: "business", icon: {name: "work"}},
    {category: TopCategory.CAREERS, localeKey: "careers", icon: {name: "engineering"}},
    {category: TopCategory.CHILDCARE, localeKey: "childcare", icon: {name: "child_friendly"}},
    {category: TopCategory.ECOLOGY_ENVIRONMENT_AND_NATURAL_RESSOURCES, localeKey: "ecology", icon: {name: "eco"}},
    {category: TopCategory.EDUCATION, localeKey: "education", icon: {name: "school"}},
    {category: TopCategory.EVENTS_AND_ATTRACTIONS, localeKey: "events", icon: {name: "nightlife"}},
    {category: TopCategory.FAMILY_AND_RELATIONSHIPS, localeKey: "life_events", icon: {name: "family_restroom"}},
    {category: TopCategory.FINE_ART, localeKey: "art", icon: {name: "theater_comedy"}},
    {category: TopCategory.FOOD_AND_DRINK, localeKey: "food", icon: {name: "fastfood"}},
    {category: TopCategory.HEALTHY_LIVING, localeKey: "well_being", icon: {name: "self_improvement"}},
    {category: TopCategory.HIGH_TECH_AND_ELECTRONICS, localeKey: "high_tech", icon: {name: "computer", outlined: true}},
    {category: TopCategory.HOBBIES_AND_INTERESTS, localeKey: "hobbies", icon: {name: "palette"}},
    {category: TopCategory.HOME_AND_GARDEN, localeKey: "home_and_garden", icon: {name: "cottage"}},
    {category: TopCategory.LANGUAGES, localeKey: "languages", icon: {name: "translate"}},
    {category: TopCategory.LIFE_EVENTS, localeKey: "life_events", icon: {name: "family_restroom"}},
    {category: TopCategory.MEDICAL_HEALTH, localeKey: "medical", icon: {name: "medical_services"}},
    {category: TopCategory.MOVIES, localeKey: "movies", icon: {name: "movie"}},
    {category: TopCategory.MUSIC_AND_AUDIO, localeKey: "music", icon: {name: "music_note"}},
    {category: TopCategory.NEWS, localeKey: "news_and_politics", icon: {name: "auto_stories"}},
    {category: TopCategory.NEWS_AND_POLITICS, localeKey: "news_and_politics", icon: {name: "auto_stories"}},
    {category: TopCategory.PERSONAL_FINANCE, localeKey: "bank_and_insurance", icon: {name: "account_balance"}},
    {category: TopCategory.PETS, localeKey: "animals", icon: {name: "pets"}},
    {category: TopCategory.PETS_AND_ANIMALS, localeKey: "animals", icon: {name: "pets"}},
    {category: TopCategory.POP_CULTURE, localeKey: "pop_culture", icon: {name: "live_tv", outlined: true}},
    {category: TopCategory.REAL_ESTATE, localeKey: "real_estate", icon: {name: "holiday_village"}},
    {category: TopCategory.RELIGION_AND_SPIRITUALITY, localeKey: "religion", icon: {name: "volunteer_activism"}},
    {category: TopCategory.SCIENCE, localeKey: "science", icon: {name: "science"}},
    {category: TopCategory.SHOPPING, localeKey: "shopping", icon: {name: "shopping_cart"}},
    {category: TopCategory.SOCIO_DEMOGRAPHICS, localeKey: "socio_demographics", icon: {name: "group"}},
    {category: TopCategory.SPORTS, localeKey: "sports", icon: {name: "fitness_center"}},
    {category: TopCategory.STYLE_AND_FASHION, localeKey: "apparel", icon: {name: "checkroom"}},
    {category: TopCategory.TECHNOLOGY_AND_COMPUTING, localeKey: "high_tech", icon: {name: "computer", outlined: true}},
    {category: TopCategory.TELEVISION, localeKey: "television", icon: {name: "tv", outlined: true}},
    {category: TopCategory.TRAVEL, localeKey: "travel", icon: {name: "flight"}},
    {category: TopCategory.VIDEO_GAMING, localeKey: "video_game", icon: {name: "sports_esports"}},
    {category: TopCategory.WELL_BEING, localeKey: "well_being", icon: {name: "self_improvement"}}
];
