import React, {FunctionComponent} from "react";
import Segments from "./Segments";
import {useTranslation} from "react-i18next";
import TagCloudItem from "./TagCloudItem";
import {CategorizeResponse} from "../api/model/CategorizeResponse";
import {Animation} from "../utils/Animation";

type HowItWorksProps = {
    result: CategorizeResponse | undefined;
}

const HowItWorks: FunctionComponent<HowItWorksProps> = ({result}) => {
    const {t} = useTranslation();
    const KEYWORDS_MAX_COUNT = 20;

    const shuffleKeywords = (keywords: Map<string, number> | undefined) => {
        if (!keywords) return;

        const result = Object.entries(keywords).map(([name, score]) => ({name, score}));
        for (let i = result.length - 1; i >= 0; i--) {
            let index = Math.floor(Math.random() * (i + 1));
            let itemAtIndex = result[index];
            result[index] = result[i];
            result[i] = itemAtIndex;
        }
        return result.slice(0, KEYWORDS_MAX_COUNT);
    };

    const rawText = result?.explanation?.raw_text.substring(0, 5000);
    const matchingIntents = shuffleKeywords(result?.explanation?.best_matching_intents);
    const categories = result?.categories;

    return (
        <section className="how-it-works">
            <h2 className="section-title">{t("how_it_works.title")}</h2>
            <div className="how-it-works-content">
                <div className="section-container">
                    <div className="section-item" data-aos={Animation.FROM_LEFT_SIDE}>
                        <div className="section-box">
                            <span className="section-box-step">1</span>
                            <div className="section-box-title">{t("how_it_works.analysis_title")}</div>
                            <div className="section-box-analysis">
                                <span>{rawText}</span>
                            </div>
                        </div>
                        <div className="section-arrow arrow-right">
                            <img src="/images/arrow-step-right.svg" alt=""/>
                        </div>
                    </div>
                    <div className="section-img section-img-right">
                        <img src="/images/how-it-works-1.svg" alt=""/>
                    </div>
                </div>
                <div className="section-container section-container-reverse">
                    <div className="section-item" data-aos={Animation.FROM_RIGHT_SIDE}>
                        <div className="section-box">
                            <span className="section-box-step">2</span>
                            <div className="section-box-title">{t("how_it_works.tag_cloud")}</div>
                            <div className="section-box-tag-cloud">
                                {matchingIntents &&
                                <div className="tag-cloud-item">
                                    {matchingIntents?.map(({name, score}, idx) =>
                                        <TagCloudItem key={idx} name={name} score={score}/>
                                    )}
                                </div>
                                }
                            </div>
                        </div>
                        <div className="section-arrow arrow-left">
                            <img src="/images/arrow-step-left.svg" alt=""/>
                        </div>
                    </div>
                    <div className="section-img section-img-left">
                        <img src="/images/how-it-works-2.svg" alt=""/>
                    </div>
                </div>
                <div className="section-container">
                    <div className="section-item" data-aos={Animation.FROM_LEFT_SIDE}>
                        <span className="section-box-step">3</span>
                        <Segments title={t("how_it_works.categories_found")} categories={categories} textError={t("how_it_works.categories_empty")} showType/>
                    </div>
                    <div className="section-img section-img-right">
                        <img src="/images/how-it-works-3.svg" alt=""/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowItWorks;
