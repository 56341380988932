import {ApiClient} from "../ApiClient";
import {Serializable} from "../interface/Serializable";

export class ApiModel implements Serializable {
    [key: string]: any;

    protected _client?: ApiClient;

    load(o: any, conf?: ApiClient): void {
        if (conf !== undefined) {
            this._client = conf;
        }
        if (typeof o === "object") {
            for (let key in o) {
                this[key] = o[key];
            }
        }
    }

    constructor(o?: {}, conf?: ApiClient) {
        this.load(o, conf);
        if (conf !== undefined) {
            this._client = conf;
        }
    }

    toJson(): string {
        return JSON.stringify(this.getJsonParameters());
    }

    getJsonParameters(): {} {
        let values = new Map<string, any>();
        for (let key in this) {
            values.set(key, this[key]);
        }
        return values;
    }
}
