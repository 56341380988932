import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";

type FormErrorProps = {
    code: string | undefined;
};

const FormError: FunctionComponent<FormErrorProps> = ({code}) => {
    const {t} = useTranslation();
    if (!code) return <></>;

    return (
        <p className="error">{t(`form.error.${code}`, t("form.error.default"))}</p>
    );
};

export default FormError;
