import React from "react";

function Loader() {
    return (
        <div className="loader-chip">
            <span/><span/><span/><span/><span/><span/><span/><span/><span/><span/><span/><span/>
        </div>
    );
}

export default Loader;
