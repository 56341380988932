import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import IconBrandSafety from "./IconBrandSafety";
import {Language, LanguageItem, LANGUAGES} from "../api/model/Language";
import {CategorizeResponse} from "../api/model/CategorizeResponse";
import {BrandSafety} from "../utils/BrandSafety";
import {translationCategory, translationLanguage} from "../utils/constants";
import Icon from "./Icon";
import {TOP_CATEGORIES, TopCategoryItem} from "../api/model/TopCategory";

type HighlightsProps = {
    result: CategorizeResponse | undefined;
}

type HighlightsState = {
    language?: LanguageItem | undefined;
    brandSafety?: BrandSafety | undefined;
    topCategory?: TopCategoryItem | undefined;
}

const Highlights: FunctionComponent<HighlightsProps> = (props) => {
    const [state, setState] = useState<HighlightsState>({});
    const {t} = useTranslation();
    const {t: textLanguage} = useTranslation(translationLanguage);
    const {t: textCategory} = useTranslation(translationCategory);

    useEffect(() => {
        if (props.result?.contextual?.languages) {
            const lang = Object.keys(props.result?.contextual?.languages)[0] as Language;
            setState((prevState) => ({...prevState, language: LANGUAGES.find(({language: l}) => l === lang)}));
        }

        const [brandSafetyCategory] = [...props.result?.contextual?.brand_safety_categories || []];

        setState((prevState) => ({...prevState,
            brandSafety: BrandSafety.forCategory(brandSafetyCategory),
            topCategory: TOP_CATEGORIES.find(({category}) => category === props.result?.top_contextual_vertical?.toUpperCase())
        }));
    }, [props]);

    return (
        <section className="highlights section-midnight">
            <h2 className="section-title">{t("highlights.title")}</h2>
            <div className="highlights-content">
                <div className="section-box">
                    <div className="section-box-title">{t("highlights.language.title")}</div>
                    {state?.language ?
                        <>
                            <div className="highlights-img-container"><img src={`/images/flags/${state?.language.img}`} alt={state?.language.language}/></div>
                            <div className="highlights-text">{t("highlights.language.text")}<strong>{textLanguage(`${state?.language.language}`)}</strong></div>
                        </> :
                        <>
                            <div className="highlights-img-container"><Icon name="language" color="var(--color-grey)"/></div>
                            <div className="highlights-text">{t("highlights.language.text_undefined")}</div>
                        </>
                    }
                </div>
                <div className="section-box">
                    <div className="section-box-title">{t("highlights.brand_safety.title")}</div>
                    <div className="highlights-img-container"><IconBrandSafety value={state?.brandSafety}/></div>
                    <div className="highlights-text">{t(`highlights.brand_safety.${state?.brandSafety?.type}`)}</div>
                </div>
                <div className="section-box">
                    <div className="section-box-title">{t("highlights.top_category.title")}</div>
                    {state?.topCategory ?
                        <>
                            <div className="highlights-img-container"><Icon {...state?.topCategory.icon} color="var(--color-blue)"/></div>
                            <div className="highlights-text">{t("highlights.top_category.text")}<strong>{textCategory(`${state?.topCategory?.localeKey}`)}</strong></div>
                        </> :
                        <>
                            <div className="highlights-img-container"><Icon name="speaker_notes_off" color="var(--color-grey)"/></div>
                            <div className="highlights-text">{t("highlights.top_category.text_undefined")}</div>
                        </>
                    }
                </div>
            </div>
        </section>
    );
};

export default Highlights;
