import {ErrorResponse} from "../http/ErrorResponse";

export class ApiError {
    static DEFAULT: ApiError = new ApiError("default");
    static TEXT_TOO_SHORT: ApiError = new ApiError("text_too_short");
    static INVALID_URL: ApiError = new ApiError("invalid_url", 204);
    static INVALID_URL_499: ApiError = new ApiError("invalid_url", 499);
    static INVALID_CAPTCHA: ApiError = new ApiError("invalid_captcha", 401, "unauthorized");
    static CRAWLER_BLOCKED_403: ApiError = new ApiError("crawler_blocked", 403);
    static CRAWLER_BLOCKED_497: ApiError = new ApiError("crawler_blocked", 497);
    static CRAWLER_BLOCKED_DATADOME: ApiError = new ApiError("crawler_blocked", 901);
    static CRAWLER_BLOCKED_AKAMAI: ApiError = new ApiError("crawler_blocked", 902);
    static CRAWLER_BLOCKED_SHIELD_SQUARE: ApiError = new ApiError("crawler_blocked", 903);
    static CONTENT_NOT_SUPPORTED: ApiError = new ApiError("content_cannot_be_analyzed", 496);
    static CONTENT_TOO_SHORT: ApiError = new ApiError("content_too_short", 498, "not_enough_content_to_categorize");
    static PAYLOAD_TOO_LARGE: ApiError = new ApiError("content_cannot_be_analyzed", 413, "payload_too_large");
    static TIMEOUT: ApiError = new ApiError("timeout", 408);

    private static errors = [
        ApiError.DEFAULT,
        ApiError.TEXT_TOO_SHORT,
        ApiError.INVALID_URL,
        ApiError.INVALID_URL_499,
        ApiError.INVALID_CAPTCHA,
        ApiError.CRAWLER_BLOCKED_403,
        ApiError.CRAWLER_BLOCKED_497,
        ApiError.CRAWLER_BLOCKED_DATADOME,
        ApiError.CRAWLER_BLOCKED_AKAMAI,
        ApiError.CRAWLER_BLOCKED_SHIELD_SQUARE,
        ApiError.CONTENT_NOT_SUPPORTED,
        ApiError.CONTENT_TOO_SHORT,
        ApiError.PAYLOAD_TOO_LARGE,
        ApiError.TIMEOUT
    ];

    translationKey: string = "";
    statusCode?: number = 0;
    keyword?: string = "";

    constructor(translationKey: string, statusCode?: number, keyword?: string) {
        this.translationKey = translationKey;
        this.statusCode = statusCode;
        this.keyword = keyword;
    }

    static fromErrorResponse(errorResponse: ErrorResponse): ApiError | undefined {
        const error = ApiError.fromStatusCode(errorResponse.status);
        if (error) {
            return error;
        }

        if (errorResponse.status === 415) {
            return ApiError.fromMessage(errorResponse.message);
        }

        return ApiError.fromKeyword(errorResponse.message);
    }

    static fromStatusCode(statusCode: number): ApiError | undefined {
        return this.errors.find((it) => it.statusCode === statusCode);
    }

    static fromMessage(message: string): ApiError | undefined {
        const error = this.errors.find((it) => message.indexOf(`[${it.statusCode}]`) !== -1);
        return error || ApiError.DEFAULT;
    }

    static fromKeyword(message: string): ApiError | undefined {
        if (!message) return ApiError.DEFAULT;

        const keyword = message.toLowerCase().replace(/\s+/g, "_");
        const error = this.errors.find((it) => it.keyword === keyword);
        return error || new ApiError(keyword);
    }
}
