import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import {translationCategory, translationLanguage} from "../utils/constants";

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ["navigator", "localStorage", "cookie"]
        },
        fallbackLng: "en",
        //debug: true,
        ns: [
            "translation",
            translationLanguage,
            translationCategory
        ],
        defaultNS: "translation"
    });

export default i18n;
