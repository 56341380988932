import {ApiModel} from "./ApiModel";
import {Contextual} from "./Contextual";
import {Category} from "./Category";
import {Explanation} from "./Explanation";

export class CategorizeResponse extends ApiModel {
    categories: Category[] = [];
    contextual: Contextual = new Contextual();
    explanation: Explanation = new Explanation();
    top_contextual_vertical: string = "";
}
