import React, {useEffect, useState} from "react";
import AOS from "aos";
import {useTranslation} from "react-i18next";
import Header from "../snippet/Header";
import Footer from "../snippet/Footer";
import QueryForm from "../snippet/QueryForm";
import Highlights from "../snippet/Highlights";
import UserCentric from "../snippet/UserCentric";
import SiteCentric from "../snippet/SiteCentric";
import HowItWorks from "../snippet/HowItWorks";
import {CategorizeResponse} from "../api/model/CategorizeResponse";
import GoToTop from "../snippet/GoToTop";
import {session} from "../api/ApiSession";
import clsx from "clsx";
import {pathHome} from "../utils/constants";
import {sirdataContactUrl} from "../utils/helper";
import {ErrorResponse} from "../api/http/ErrorResponse";
import {ApiError} from "../api/model/ApiError";

function Home() {
    const {t} = useTranslation();

    const urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
    const [queryUrl, setQueryUrl] = useState<string>("");
    const [queryText, setQueryText] = useState<string>("");
    const [isLoading, setLoading] = useState(false);
    const [apiError, setApiError] = useState<ApiError>();
    const [result, setResult] = useState<CategorizeResponse>();
    const [showGoToTop, setShowGoToTop] = useState(false);

    const runAnalyse = async (queryUrl: string, queryText: string, captcha: string) => {
        if (queryUrl && !(new RegExp(urlPattern).test(queryUrl))) {
            showError(ApiError.INVALID_URL);
            return;
        }
        if (queryText && queryText.length < 500) {
            showError(ApiError.TEXT_TOO_SHORT);
            return;
        }

        setLoading(true);
        setApiError(undefined);
        setQueryUrl(queryUrl);
        setQueryText(queryText);
        setResult(undefined);
        setShowGoToTop(false);

        try {
            if (queryUrl.indexOf("http") === -1) queryUrl = `https://${queryUrl}`;
            const result = await session.getCategorization(queryUrl, queryText, captcha);
            setResult(result);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                showError(ApiError.fromErrorResponse(e));
            } else {
                showError(ApiError.DEFAULT);
            }
        } finally {
            setLoading(false);
            window.scrollTo(0, 0);
        }
    };

    const showError = (error?: ApiError) => {
        setApiError(error);
        setTimeout(() => setApiError(undefined), 10000);
    };

    useEffect(() => {
        AOS.init({
            offset: 500,
            once: true
        });
    }, []);

    useEffect(() => {
        function toggleGoToTop() {
            if (showGoToTop && window.pageYOffset < 550)
                setShowGoToTop(false);
            else if (!showGoToTop && window.pageYOffset > 550)
                setShowGoToTop(true);
        }

        window.addEventListener("scroll", toggleGoToTop, {passive: true});
        return () => window.removeEventListener("scroll", toggleGoToTop);
    });

    const handleContactUs = () => {
        window.open(sirdataContactUrl);
    };

    const handleGoToHome = () => {
        window.location.href = pathHome;
    };

    return (
        <div className="wrapper">
            <Header/>
            {!result ?
                <div className="main-content homepage">
                    <h1>{t("title")}</h1>
                    <QueryForm onSubmit={runAnalyse} isLoading={isLoading} apiError={apiError}/>
                </div> :
                <>
                    <div className="result-header">
                        <QueryForm
                            onSubmit={runAnalyse}
                            url={queryUrl}
                            text={queryText}
                            isResultPage
                            isLoading={isLoading}
                            apiError={apiError}
                        />
                    </div>
                    <div className="main-content">
                        <Highlights result={result}/>

                        <UserCentric categories={result?.categories} contextual={result?.contextual}/>

                        <SiteCentric categories={result?.categories} contextual={result?.contextual}/>

                        <section className="action-buttons">
                            <div className="action-buttons-content">
                                <button className={clsx("btn", "btn-big", "btn-primary-green")} onClick={handleContactUs}>{t("result_actions.contact_us")}</button>
                            </div>
                        </section>

                        <HowItWorks result={result}/>

                        <section className="action-buttons">
                            <div className="action-buttons-content">
                                <button className={clsx("btn", "btn-big", "btn-primary-green")} onClick={handleContactUs}>{t("result_actions.contact_us")}</button>
                                <button className={clsx("btn", "btn-big", "btn-primary-green")} onClick={handleGoToHome}>{t("form.rerun_analyse")}</button>
                            </div>
                        </section>
                    </div>
                </>
            }
            {showGoToTop && <GoToTop/>}
            <Footer/>
        </div>
    );
}

export default Home;
