import {CategoryType} from "../api/model/CategoryType";

export class SegmentType {
    static INTEREST: SegmentType = new SegmentType(CategoryType.INTEREST, "pink");
    static INTENT: SegmentType = new SegmentType(CategoryType.INTENT, "blue");
    static CONTEXTUAL: SegmentType = new SegmentType(CategoryType.CONTEXTUAL, "purple");

    private static types = [
        SegmentType.INTEREST,
        SegmentType.INTENT,
        SegmentType.CONTEXTUAL
    ];

    type: CategoryType;
    color: string = "";

    constructor(type: CategoryType, color: string) {
        this.type = type;
        this.color = `var(--color-${color})`;
    }

    static forType(type?: CategoryType): SegmentType | undefined {
        return this.types.find((it) => it.type === type);
    }
}
