import React from "react";
import {useTranslation} from "react-i18next";
import {sirdataAboutUsUrl, sirdataLegalNoticeUrl} from "../utils/helper";

function Footer() {
    const {t} = useTranslation();
    return (
        <div className="footer">
            <a className="link" href={sirdataLegalNoticeUrl} target="_blank" rel="noopener noreferrer"><span>{t("footer.legal_notice")}</span></a>
            <a className="link" href={sirdataAboutUsUrl} target="_blank" rel="noopener noreferrer"><span>{t("footer.about_sirdata")}</span></a>
        </div>
    );
};

export default Footer;
