import React, {Suspense} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Home from "./screen/Home";
import ScrollToTop from "./snippet/ScrollToTop";
import {pathHome} from "./utils/constants";

function App() {
    return (
        <React.StrictMode>
            <Suspense fallback={<></>}>
                <BrowserRouter>
                    <ScrollToTop/>
                    <Routes>
                        <Route path={pathHome} element={<Home/>}/>
                        <Route path="*" element={<Navigate to={pathHome}/>}/>
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </React.StrictMode>
    );
}

export default App;
