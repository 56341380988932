import React, {FunctionComponent} from "react";
import Icon from "./Icon";
import {ContextualCategory} from "../api/model/ContextualCategory";
import IconBrandSafety from "./IconBrandSafety";
import {BrandSafety} from "../utils/BrandSafety";
import {useTranslation} from "react-i18next";

type ContextualCategoriesProps = {
    categories: ContextualCategory[] | undefined;
    animation?: string;
};

const BrandSafetyCategories: FunctionComponent<ContextualCategoriesProps> = (props) => {
    const {t} = useTranslation();
    const {
        categories,
        animation
    } = props;
    const CATEGORIES_COUNT = 4;
    const filteredCategories = categories?.map((it) => ({...it, brandSafety: BrandSafety.forCategory(it)})).slice(0, CATEGORIES_COUNT);

    return (
        <div className="section-box section-box-small" data-aos={animation}>
            <div className="section-box-title">{t("site_centric.brand_safety_categories")}</div>
            {
                filteredCategories?.length ?
                    <div className="section-box-row">
                        <div className="section-box-content">
                            {filteredCategories?.slice(0, CATEGORIES_COUNT).map(({name, relevancy, brandSafety}, idx) =>
                                <div key={idx} className="segment-item">
                                    <div className="segment-title">{name}</div>
                                    <div className="segment-progress">
                                        <div className="segment-progress-bars">
                                            <span className="bar back"/>
                                            <span className="bar track" style={{borderColor: brandSafety.color, width: relevancy * 100 + "%"}}/>
                                        </div>
                                        <div className="segment-progress-value">{Math.round(relevancy * 100)} %</div>
                                    </div>
                                </div>
                            )}
                            {
                                filteredCategories.length < CATEGORIES_COUNT &&
                                Array.from(new Array(CATEGORIES_COUNT - filteredCategories.length)).map((i) => (
                                    <div key={i} className="segment-item">
                                        <div className="segment-title">&nbsp;</div>
                                        <div className="segment-progress">
                                            <div className="segment-progress-bars">
                                                <span className="bar back"/>
                                                <span className="bar track" style={{display: "none"}}/>
                                            </div>
                                            <div className="segment-progress-value">N/A</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="section-box-img">
                            <IconBrandSafety value={filteredCategories[0].brandSafety}/>
                        </div>
                    </div> :
                    <div className="section-box-content">
                        <div className="content-error">
                            <Icon name="speaker_notes_off" cssClass="content-error-icon"/>
                            <span>{t("site_centric.brand_safety_categories_error")}</span>
                        </div>
                    </div>
            }
        </div>
    );
};

export default BrandSafetyCategories;
