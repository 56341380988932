import React, {useState} from "react";
import clsx from "clsx";
import {pathHome} from "../utils/constants";
import Icon from "./Icon";
import MainLogo from "./MainLogo";
import {sirdataAboutUsUrl, sirdataContactUrl} from "../utils/helper";
import {useTranslation} from "react-i18next";

type MenuItem = {
    label: string;
    url: string;
}

const menuItems: MenuItem[] = [
    {label: "audiences", url: "https://audiences.sirdata.com/"},
    {label: "semantic_api", url: "https://semantic-api.docs.sirdata.io/"},
    {label: "contact", url: sirdataContactUrl},
    {label: "about_sirdata", url: sirdataAboutUsUrl}
];

function Header() {
    const {t} = useTranslation();
    const [showBurgerMenu, toggleShowBurgerMenu] = useState(false);

    return (
        <>
            <div className="main-header">
                <div className={clsx("burger-menu", {"visible": showBurgerMenu})}>
                    <div className="burger-menu-btn" onClick={() => toggleShowBurgerMenu((prev) => !prev)}><Icon name="menu"/></div>
                    <div className="burger-menu-content">
                        <div className="burger-menu-overlay" onClick={() => toggleShowBurgerMenu(false)}/>
                        <div className="burger-menu-panel">
                            <div className="navigation">
                                <span className="nav-close" onClick={() => toggleShowBurgerMenu(false)}><Icon name="close"/></span>
                                <ul className="nav-items">
                                    {menuItems.map(({label, url}, index) =>
                                        <li key={index}>
                                            <a href={url} target="_blank" rel="noopener noreferrer">{t(`header.${label}`)}</a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-nav">
                    <div className="main-logo">
                        <a href={pathHome}><MainLogo/></a>
                    </div>
                    <div className="nav">
                        {menuItems.map(({label, url}, index) =>
                            <a
                                key={index}
                                className="nav-link"
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >{t(`header.${label}`)}
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;
