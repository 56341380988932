import {ApiModel} from "./ApiModel";
import {ApiClient} from "../ApiClient";
import {Language} from "./Language";
import {ContextualCategory} from "./ContextualCategory";

export class Contextual extends ApiModel {
    brand_safety_categories: ContextualCategory[] | undefined;
    contextual_segments: Map<string, ValueWithProbability> | undefined;
    _languages: Map<Language, number> | undefined;
    error: string | undefined;

    constructor(o?: {}, conf?: ApiClient) {
        super(o, conf);
        this.load(o);
    }

    get languages(): Map<Language, number> | undefined {
        return this._languages;
    }

    set languages(t: Map<Language, number> | undefined) {
        if (!t) {
            this._languages = t;
            return;
        }

        this._languages = Object.entries(t).length ? new Map(Object.entries(t)) as Map<Language, number> : t;
    }
}

export class ValueWithProbability extends ApiModel {
    value: string = "";
    probability: number = 0;

    constructor(o?: {}, conf?: ApiClient) {
        super(o, conf);
        this.load(o);
    }
}
