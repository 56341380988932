import React, {FunctionComponent, useEffect, useState} from "react";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import SearchBar from "./SearchBar";
import {pathHome, recaptchaKey} from "../utils/constants";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "./Loader";
import FormError from "./FormError";
import {ApiError} from "../api/model/ApiError";

export enum QueryMode {
    Url,
    Text
}

type QueryFormProps = {
    url?: string | "";
    text?: string | "";
    onSubmit: (queryUrl: string, queryText: string, captcha: string) => void;
    isResultPage?: boolean;
    isLoading?: boolean;
    apiError: ApiError | undefined;
}

const QueryForm: FunctionComponent<QueryFormProps> = (props) => {
    const {t} = useTranslation();
    const {
        url,
        text,
        onSubmit,
        isResultPage,
        isLoading,
        apiError
    } = props;

    const [queryMode, setQueryMode] = useState<QueryMode>(QueryMode.Url);
    const [queryUrl, setQueryUrl] = useState<string>("");
    const [queryText, setQueryText] = useState<string>("");
    const [captcha, setCaptcha] = useState<string>("");

    const handleQueryMode = (queryMode: QueryMode) => {
        return () => {
            setQueryMode(queryMode);
            if (queryMode === QueryMode.Url) {
                setQueryText("");
            } else {
                setQueryUrl("");
            }
        };
    };

    const handleSubmit = () => {
        onSubmit(queryUrl, queryText, captcha);
    };

    const handleCaptcha = (value: string | null) => {
        setCaptcha(value || "");
    };

    const goToHome = () => {
        window.location.href = pathHome;
    };

    useEffect(() => {
        if (url) {
            setQueryUrl(url);
            setQueryMode(QueryMode.Url);
        } else if (text) {
            setQueryText(text);
            setQueryMode(QueryMode.Text);
        }
    }, [url, text]);

    return (
        <div className={clsx("form-container", {"form-container-slim": isResultPage})}>
            {isLoading ?
                <div className="form-row">
                    <Loader/>
                    <p dangerouslySetInnerHTML={{__html: t("form.analysis_in_progress")}}/>
                </div> :
                <>
                    {isResultPage ?
                        <>
                            <div className="form-row">
                                <div className="form-column">
                                    {queryMode === QueryMode.Url ?
                                        <SearchBar
                                            value={queryUrl}
                                            placeholder={t("form.placeholder_url")}
                                            onChange={(e) => setQueryUrl(e.target.value)}
                                            onReset={() => setQueryUrl("")}
                                            disabled
                                        /> :
                                        <textarea
                                            name="text"
                                            rows={10}
                                            value={queryText}
                                            onChange={(e) => setQueryText(e.target.value)}
                                            placeholder={t("form.placeholder_text")}
                                            disabled
                                        />
                                    }
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-column">
                                    <button type="submit" className={clsx("btn", "btn-medium", "btn-primary-green")} onClick={goToHome}>
                                        {t("form.rerun_analyse")}
                                    </button>
                                </div>
                            </div>
                        </> :
                        <>
                            <div className="form-row">
                                <div className="form-column">
                                    <div className="btn-group">
                                        <button className={clsx("btn btn-small", {"selected": queryMode === QueryMode.Url})} onClick={handleQueryMode(QueryMode.Url)}>
                                            <span>{t("form.query_mode_url")}</span>
                                        </button>
                                        <button className={clsx("btn btn-small", {"selected": queryMode === QueryMode.Text})} onClick={handleQueryMode(QueryMode.Text)}>
                                            <span>{t("form.query_mode_text")}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-column">
                                    {queryMode === QueryMode.Url ?
                                        <SearchBar value={queryUrl} placeholder={t("form.placeholder_url")} onChange={(e) => setQueryUrl(e.target.value)} onReset={() => setQueryUrl("")}/> :
                                        <textarea
                                            name="text"
                                            rows={10}
                                            value={queryText}
                                            onChange={(e) => setQueryText(e.target.value)}
                                            placeholder={t("form.placeholder_text")}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-column">
                                    <ReCAPTCHA sitekey={recaptchaKey} onChange={handleCaptcha}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-column">
                                    <button type="submit" className={clsx("btn", "btn-big", "btn-primary-green")} onClick={handleSubmit} disabled={(queryMode === QueryMode.Url && !queryUrl) || (queryMode === QueryMode.Text && !queryText)}>
                                        {t("form.run_analyse")}
                                    </button>
                                </div>
                                <FormError code={apiError?.translationKey}/>
                            </div>
                        </>
                    }
                </>
            }
        </div>
    );
};

export default QueryForm;
