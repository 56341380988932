import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import Segments from "./Segments";
import {Category} from "../api/model/Category";
import {CategoryType} from "../api/model/CategoryType";
import {Animation} from "../utils/Animation";
import {Contextual} from "../api/model/Contextual";

type UserCentricProps = {
    categories: Category[] | undefined;
    contextual: Contextual | undefined;
}

const UserCentric: FunctionComponent<UserCentricProps> = ({categories, contextual}) => {
    const {t} = useTranslation();

    const interestCategories = categories?.filter(({type}) => type === CategoryType.INTEREST);
    const intentCategories = categories?.filter(({type}) => type === CategoryType.INTENT);
    const brandSafetyCategories = contextual?.brand_safety_categories;

    return (
        <section className="user-centric">
            <div className="user-centric-title">
                <h2 className="section-title">{t("user_centric.title")}</h2>
                <img src="/images/user-centric.svg" alt={t("user_centric.title")}/>
            </div>
            <div className="user-centric-content">
                <Segments
                    title={t("user_centric.interest_categories")}
                    categories={interestCategories}
                    textError={t("user_centric.interest_categories_error")}
                    animation={Animation.FROM_RIGHT_SIDE}
                    offset={Animation.SCROLL_START_OFFSET}
                />
                <Segments title={t("user_centric.intent_categories")} categories={intentCategories} textError={brandSafetyCategories?.length ? t("user_centric.intent_categories_brand_safety_error") : t("user_centric.intent_categories_error")} animation={Animation.FROM_RIGHT_SIDE}/>
            </div>
        </section>
    );
};

export default UserCentric;
