import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {EventEmitter} from "events";
import {Model} from "./interface/Model";
import {Serializable} from "./interface/Serializable";
import {ApiModel} from "./model/ApiModel";
import {ErrorResponse} from "./http/ErrorResponse";
import {gatewayRoot} from "../utils/helper";

export enum SirdataApiEvent {
    eventUnauthorizedRequest = "unauthorized_request"
}

export class ApiClient {
    private httpClient: AxiosInstance;
    public events: EventEmitter;

    constructor() {
        this.events = new EventEmitter();
        this.httpClient = axios.create({
            baseURL: gatewayRoot,
            maxRedirects: 2,
            validateStatus: (status) => status === 200
        } as AxiosRequestConfig);
        this.httpClient.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (error.isAxiosError) {
                let err = error as AxiosError;
                return Promise.reject(err);
            }
        });
    }

    buildRequestConfig(options: any, contentType?: string): AxiosRequestConfig {
        options = options || {};
        options["headers"] = options["headers"] || {};
        if (contentType !== undefined && options["headers"]["Content-Type"] === undefined) {
            options["headers"]["Content-Type"] = contentType;
        }
        return options as AxiosRequestConfig;
    }

    public async post(model: ApiModel, path: string, body: Serializable, options?: {}): Promise<Model> {
        try {
            let opts = this.buildRequestConfig(options, "application/json");
            const resp = await this.httpClient.post(path, body.toJson(), opts) as AxiosResponse<Model>;
            model.load(resp.data, this);
            return model;
        } catch (error) {
            throw new ErrorResponse(error as AxiosError);
        }
    }
}
