export enum Language {
    ARABIC = "ara",
    BENGALI = "ben",
    CZECH = "ces",
    CHINESE = "cmn",
    DANISH = "dan",
    DUTCH = "nld",
    ENGLISH = "eng",
    GREEK = "ell",
    FINNISH = "fin",
    FRENCH = "fra",
    GERMAN = "deu",
    HEBREW = "heb",
    HINDI = "hin",
    INDONESIAN = "ind",
    ITALIAN = "ita",
    JAPANESE = "jpn",
    KOREAN = "kor",
    MALAY = "msa",
    NORWEGIAN = "nob",
    PERSIAN = "fas",
    POLISH = "pol",
    PORTUGUESE = "por",
    ROMANIAN = "ron",
    RUSSIAN = "rus",
    SPANISH = "spa",
    SWEDISH = "swe",
    THAI = "tha",
    TURKISH = "tur",
    UKRAINIAN = "ukr",
    URDU = "urd",
    VIETNAMESE = "vie"
}

export type LanguageItem = {
    language: Language;
    img: string;
}

export const LANGUAGES: LanguageItem[] = [
    {language: Language.ARABIC, img: "ar.png"},
    {language: Language.BENGALI, img: "ind.png"},
    {language: Language.CZECH, img: "cs.png"},
    {language: Language.CHINESE, img: "chi"},
    {language: Language.DANISH, img: "da.png"},
    {language: Language.DUTCH, img: "nl.png"},
    {language: Language.ENGLISH, img: "en.png"},
    {language: Language.GREEK, img: "el.png"},
    {language: Language.FINNISH, img: "fi"},
    {language: Language.FRENCH, img: "fr.png"},
    {language: Language.GERMAN, img: "de.png"},
    {language: Language.HEBREW, img: "is.png"},
    {language: Language.HINDI, img: "ind.png"},
    {language: Language.INDONESIAN, img: "indo.png"},
    {language: Language.ITALIAN, img: "it.png"},
    {language: Language.JAPANESE, img: "ja.png"},
    {language: Language.KOREAN, img: "ko.png"},
    {language: Language.MALAY, img: "mal.png"},
    {language: Language.NORWEGIAN, img: "no.png"},
    {language: Language.PERSIAN, img: "ir.png"},
    {language: Language.POLISH, img: "pl.png"},
    {language: Language.PORTUGUESE, img: "pt.png"},
    {language: Language.ROMANIAN, img: "ro.png"},
    {language: Language.RUSSIAN, img: "ru.png"},
    {language: Language.SPANISH, img: "es.png"},
    {language: Language.SWEDISH, img: "sv.png"},
    {language: Language.THAI, img: "tha.png"},
    {language: Language.TURKISH, img: "tu.png"},
    {language: Language.UKRAINIAN, img: "uk.png"},
    {language: Language.URDU, img: "ind.png"},
    {language: Language.VIETNAMESE, img: "vi.png"}
];
