import {Locale} from "./Locale";

const isLocalhost = window.location.origin.includes("localhost");
const isPreprod = window.location.origin.includes("demo-preprod");

export const usePreprod = isLocalhost || isPreprod;

export const gatewayRoot = isLocalhost ? "http://localhost:4268/api/v1/" : (usePreprod ? "https://gateway-preprod.sirdata.io/api/v1/public" : "https://gateway.sirdata.io/api/v1/public");
export const categorizerApiPath = isLocalhost ? "/demo/contextual" : "/categorize-api-demo/contextual";

export const sirdataContactUrl = Locale.isFrench() ? "https://www.sirdata.com/fr/Contact" : "https://www.sirdata.com/en/Contact";
export const sirdataAboutUsUrl = Locale.isFrench() ? "https://www.sirdata.com/fr/" : "https://www.sirdata.com/en/";
export const sirdataLegalNoticeUrl = Locale.isFrench() ? "https://www.sirdata.com/fr/mention-legales" : "https://www.sirdata.com/en/Legal-Mentions";

export function onKeyEnter(e: any, func: () => void) {
    const keyEnter = e.key;
    if (keyEnter === "Enter") func();
}
