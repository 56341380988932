import React, {FunctionComponent} from "react";
import Icon from "./Icon";

const GoToTop: FunctionComponent = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    };

    return (
        <button className="btn-scroll" onClick={scrollToTop}>
            <Icon name="keyboard_arrow_up"/>
        </button>
    );
};

export default GoToTop;
