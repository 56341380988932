import {ApiClient, SirdataApiEvent} from "./ApiClient";
import {AxiosError} from "axios";
import {CategorizeResponse} from "./model/CategorizeResponse";
import {CategorizeRequest} from "./model/CategorizeRequest";
import {categorizerApiPath} from "../utils/helper";

export declare type SirdataEventHandler = (error: AxiosError, args?: {}) => void;

export class ApiSession {
    _client: ApiClient;

    constructor() {
        this._client = new ApiClient();
    }

    static GetCurrentSession(): ApiSession {
        return new ApiSession();
    }

    on(eventType: SirdataApiEvent, f: SirdataEventHandler) {
        this._client.events.on(eventType, (err: AxiosError, args?: {}) => {
            f(err, args);
        });
    }

    getCategorization(url: string, body: string, captchaResponse: string): Promise<CategorizeResponse> {
        let request = new CategorizeRequest();
        request.url = url;
        request.body = body;
        request.captcha_response = captchaResponse;
        return this._client.post(new CategorizeResponse(), categorizerApiPath, request) as Promise<CategorizeResponse>;
    }

}

export const session = ApiSession.GetCurrentSession();
