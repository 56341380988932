import {ApiModel} from "./ApiModel";

export class CategorizeRequest extends ApiModel {
    url: string = "";
    body: string = "";
    captcha_response: string = "";

    toJson(): string {
        return JSON.stringify(this.getJsonParameters());
    }

    getJsonParameters(): {} {
        return {
            url: this.url,
            body: this.body,
            captcha_response: this.captcha_response
        };
    }
}
