import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Segments from "./Segments";
import PercentCircle from "./PercentCircle";
import {Category} from "../api/model/Category";
import {CategoryType} from "../api/model/CategoryType";
import {Contextual, ValueWithProbability} from "../api/model/Contextual";
import BrandSafetyCategories from "./BrandSafetyCategories";
import {ContextualCategory} from "../api/model/ContextualCategory";
import {Animation} from "../utils/Animation";

type SiteCentricProps = {
    categories: Category[] | undefined;
    contextual: Contextual | undefined;
}

type SiteCentricState = {
    contextualCategories?: Category[] | undefined;
    brandSafetyCategories?: ContextualCategory[] | undefined;
    gender?: ValueWithProbability | null;
    age?: ValueWithProbability | null;
}

const SiteCentric: FunctionComponent<SiteCentricProps> = (props) => {
    const [state, setState] = useState<SiteCentricState>({});
    const {t} = useTranslation();

    useEffect(() => {
        setState((prevState) => ({...prevState,
            contextualCategories: props.categories?.filter(({type}) => type === CategoryType.CONTEXTUAL),
            brandSafetyCategories: props.contextual?.brand_safety_categories
        }));

        if (props.contextual?.contextual_segments) {
            const entries = Object.entries(props.contextual?.contextual_segments).map(([name, value]) => ({name, value: value ? new ValueWithProbability(value) : null}));
            setState((prevState) => ({...prevState,
                gender: entries.find(({name}) => name === "gender")?.value,
                age: entries.find(({name}) => name === "age")?.value
            }));
        }
    }, [props]);

    return (
        <section className="site-centric section-midnight">
            <div className="site-centric-content">
                <div className="site-centric-title">
                    <h2 className="section-title">{t("site_centric.title")}</h2>
                    <img src="/images/site-centric.svg" alt={t("site_centric.title")}/>
                </div>
                <div className="site-centric-segments">
                    <Segments title={t("site_centric.contextual_categories")} categories={state?.contextualCategories} textError={t("site_centric.contextual_categories_error")} animation={Animation.FROM_LEFT_SIDE}/>
                    <BrandSafetyCategories categories={state?.brandSafetyCategories} animation={Animation.FROM_LEFT_SIDE}/>
                </div>
            </div>
            {(state?.gender || state?.age) &&
            <div className="site-centric-content-centered">
                <div className="site-centric-subtitle">
                    <h3 className="section-subtitle">{t("site_centric.socio_demo.title")}</h3>
                    <span className="section-centric-text">{t("site_centric.socio_demo.description")}</span>
                </div>
                <div className="site-centric-socio-demo">
                    {state?.gender ?
                        <PercentCircle
                            title={t(`site_centric.socio_demo.gender.${state?.gender.value}`)}
                            value={state?.gender.probability}
                            color="var(--color-purple)"
                            icon={state?.gender.value.toLowerCase()}
                            animation={Animation.FROM_LEFT_SIDE}
                        /> :
                        <PercentCircle
                            title={t("site_centric.socio_demo.gender.title")}
                            text={t("site_centric.socio_demo.gender.text")}
                            color="var(--color-grey)"
                            icon="wc"
                            animation={Animation.FROM_LEFT_SIDE}
                        />
                    }
                    {state?.age ?
                        <PercentCircle
                            title={t("site_centric.socio_demo.age_range.value", {value: state?.age.value})}
                            value={state?.age.probability}
                            color="var(--color-blue)"
                            icon="groups"
                            animation={Animation.FROM_RIGHT_SIDE}
                        /> :
                        <PercentCircle
                            title={t("site_centric.socio_demo.age_range.title")}
                            text={t("site_centric.socio_demo.age_range.text")}
                            color="var(--color-grey)"
                            icon="groups"
                            animation={Animation.FROM_RIGHT_SIDE}
                        />
                    }
                </div>
            </div>
            }
        </section>
    );
};

export default SiteCentric;
