import React, {FunctionComponent} from "react";
import Icon from "./Icon";
import {Category} from "../api/model/Category";
import {useTranslation} from "react-i18next";
import {translationCategory} from "../utils/constants";
import {SegmentType} from "../utils/SegmentType";

type SegmentsProps = {
    title: string;
    categories: Category[] | undefined;
    textError?: string | undefined;
    animation?: string;
    offset?: string;
    showType?: boolean;
};

const Segments: FunctionComponent<SegmentsProps> = (props) => {
    const {t} = useTranslation(translationCategory);
    const {
        title,
        categories,
        textError,
        animation,
        offset,
        showType
    } = props;
    const SEGMENTS_COUNT = 4;
    const filteredCategories = categories?.filter(({code_name}) => !code_name.startsWith("CUSTOM/"))?.slice(0, SEGMENTS_COUNT);

    return (
        <div className="section-box section-box-small" data-aos={animation} data-aos-offset={offset}>
            <div className="section-box-title">{title}</div>
            <div className="section-box-content">
                {
                    filteredCategories?.length ?
                        <>
                            {filteredCategories.map(({name, code_name, type, score}, idx) =>
                                <div key={idx} className="segment-item">
                                    <div className="segment-title">{code_name.replaceAll("/", " / ").toLowerCase()} {showType ? <strong>({t(`types.${type}`)})</strong> : ""}</div>
                                    <div className="segment-progress">
                                        <div className="segment-progress-bars">
                                            <span className="bar back"/>
                                            <span className="bar track" style={{backgroundColor: SegmentType.forType(type)?.color, borderColor: SegmentType.forType(type)?.color, width: score / 10 + "%"}}/>
                                        </div>
                                        <div className="segment-progress-value">{Math.round(score / 10)} %</div>
                                    </div>
                                </div>
                            )}
                            {
                                filteredCategories.length < SEGMENTS_COUNT &&
                                Array.from(new Array(SEGMENTS_COUNT - filteredCategories.length)).map((i) => (
                                    <div key={i} className="segment-item">
                                        <div className="segment-title">&nbsp;</div>
                                        <div className="segment-progress">
                                            <div className="segment-progress-bars">
                                                <span className="bar back"/>
                                                <span className="bar track" style={{display: "none"}}/>
                                            </div>
                                            <div className="segment-progress-value"></div>
                                        </div>
                                    </div>
                                ))
                            }
                        </> :
                        <div className="content-error">
                            <Icon name="speaker_notes_off" cssClass="content-error-icon"/>
                            <span>{textError}</span>
                        </div>
                }
            </div>
        </div>
    );
};

export default Segments;
