import React, {FunctionComponent} from "react";
import Icon from "./Icon";
import {BrandSafety} from "../utils/BrandSafety";

type IconBrandSafetyProps = {
    value: BrandSafety | undefined;
};

const IconBrandSafety: FunctionComponent<IconBrandSafetyProps> = (props) => {
    const {value} = props;

    if (!value) return <></>;
    return (
        <span style={{color: value.color}}><Icon name={value.icon}/></span>
    );
};

export default IconBrandSafety;
