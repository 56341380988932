import React, {FunctionComponent} from "react";
import clsx from "clsx";

export type IconProps = {
    name: string;
    cssClass?: string;
    outlined?: boolean;
    onClick?: () => void;
    color?: string;
};

const Icon: FunctionComponent<IconProps> = (props) => {
    const {
        name,
        cssClass,
        outlined,
        onClick,
        color
    } = props;

    return (
        <i
            style={{
                color,
                fontVariationSettings: `'FILL' ${outlined ? 0 : 1}, 'wght' 400, 'GRAD' 0, 'opsz' 24`
            }}
            className={clsx("material-symbols-rounded", cssClass)}
            onClick={onClick}
        >
            {name}
        </i>
    );
};

export default Icon;
