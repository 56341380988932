import React, {FunctionComponent} from "react";
import Icon from "./Icon";
import {useTranslation} from "react-i18next";
import clsx from "clsx";

type PercentCircleProps = {
    title: string;
    text?: string;
    value?: number;
    color: string;
    icon: string;
    animation?: string;
};

const PercentCircle: FunctionComponent<PercentCircleProps> = (props) => {
    const {t} = useTranslation();
    const {
        title,
        text,
        value,
        color,
        icon,
        animation
    } = props;
    const probability = Math.floor((value || 0) * 100);

    return (
        <div className="percent-circle-container" data-aos={animation}>
            <div className={clsx("percent-circle", `p${probability}`)}>
                <div className="percent-circle-value">
                    <Icon cssClass="percent-circle-icon" name={icon} color={color}/>
                    {text ?
                        <span className="percent-circle-label">{title}</span> :
                        <>
                            <span className="percent-circle-label">{t("site_centric.socio_demo.probability")}</span>
                            <span className="percent-circle-number">{probability}%</span>
                        </>
                    }
                </div>
                <div className="percent-circle-track">
                    <div className="percent-circle-run" style={{borderColor: color}}/>
                    <div className="percent-circle-fixed" style={{borderColor: color}}/>
                </div>
            </div>
            {text ?
                <div className="percent-circle-text">{text}</div> :
                <div className="percent-circle-title">{title}</div>
            }
        </div>
    );
};

export default PercentCircle;
