export class Locale {
    static FRENCH = "fr";
    static ENGLISH = "en";

    private static currentLocale = navigator && (navigator.language || (navigator.languages && navigator.languages[0]) || Locale.FRENCH).toLowerCase().substr(0, 2);

    static getCurrentLocale(): string {
        return this.currentLocale;
    }

    static isFrench(): boolean {
        return this.currentLocale === this.FRENCH;
    }
}
